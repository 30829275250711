import React, { useState } from 'react';
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown/with-html'
import styled from "styled-components"
import Layout from '../layouts'

import summary1 from '../assets/images/iceberg-icon-cut-1.png'
import summary2 from '../assets/images/iceberg-icon-cut-2.png'
import summary3 from '../assets/images/iceberg-icon-cut-3.png'
  

const BlogPost = ({ data }) => {
  const {title, body, author, summaryLevel1, summaryLevel2} = data.contentfulArticle

  const [optionSummary, setOptionSummary] = useState([1,0,0]);
  const [content, setContent] = useState(summaryLevel1.childMarkdownRemark.html)

  const { next } = data.allContentfulArticle.edges[0]
  const { previous } = data.allContentfulArticle.edges[1]

  return (
    <Layout>
      <Container style={{Container}}>
        <div>
          <h3>{title}</h3>
          <div className="author">
            <Link to={`/author/${author.slug}`}>
              <img src={author.thumbnail?.resize?.src} 
                alt={author.thumbnail?.description}/>
              <span>{author.name}</span>
            </Link>
          </div>        
          <ul className="summaryLevel">
            <li 
              style={{
                  backgroundColor: optionSummary[0] ? '#5b7393' : '',
                  borderRadius: '10px'
                }}
              >
                <input 
                  type="checkbox" 
                  id="myCheckbox1" 
                  onClick={() => 
                    {
                      setOptionSummary([1,0,0])
                      setContent(summaryLevel1.childMarkdownRemark.html)
                    }
                  } 
                />
                <label for="myCheckbox1">
                  <img src={summary1} alt="Summary with level 1 for resume" />
                </label>
            </li>
            <li
              style={{
                backgroundColor: optionSummary[1] ? '#5b7393' : '',
                borderRadius: '10px'
              }}
            >
              <input 
                type="checkbox" 
                id="myCheckbox2" 
                onClick={() => {
                  setOptionSummary([0,1,0])
                  setContent(summaryLevel2.childMarkdownRemark.html)
                }
              }
              />
              <label for="myCheckbox2">
                <img src={summary2} alt="Summary with level 2 for resume" />
              </label>
            </li>
            <li
              style={{
                backgroundColor: optionSummary[2] ? '#5b7393' : '',
                borderRadius: '10px'
              }}
            >
              <input 
                type="checkbox" 
                id="myCheckbox3" 
                onClick={() => {
                  setOptionSummary([0,0,3])
                  setContent(body.body)
                }
              }
              />
              <label for="myCheckbox3">
                <img className="icon3" src={summary3} 
                  alt="Summary with level 3 for resume" />
              </label>
            </li>
          </ul>
        </div>
        <div className='body'>
          <ReactMarkdown source={content} escapeHtml={false} />
        </div>
        <Suggestion>
          {
            previous && (
              <SuggestionLink onClick={() => window.location.href = `/blog/${previous.slug}`}> 
                <img 
                  src={next.thumbnail.resize.src} 
                  alt={next.thumbnail.description}
                />
                {previous.title}
              </SuggestionLink>            
            )
          }
          {
            next && (
              <SuggestionLink onClick={() => window.location.href = `/blog/${next.slug}`}> 
                <img 
                  src={next.thumbnail.resize.src} 
                  alt={next.thumbnail.description}
                />
                {next.title}               
              </SuggestionLink>
            )
          }            
        </Suggestion>
      </Container>      
    </Layout>
  )
}

const Container = styled.div` 

  background: ${props => props.theme.background};  
  margin: 0 auto;
  padding-bottom: 20px;

  li, pre, code {
    font-size: 22px;
    color: ${props => props.theme.text};
    opacity: .87;
    font-family: sans-serif;
  }
  h1, h2, h3, h4 {
    color: ${props => props.theme.text};
    opacity: .87;
    text-align: center;
    padding: 0px 25px 0px 25px;
    font-size: 28px;
    font-family: sans-serif;
    margin-bottom: 10px;
    letter-spacing: 1.2px;
    line-height:125%;
    word-spacing: 6px;
  }
  pre {
    color: ${props => props.theme.text};
    opacity: .87;
    margin: 0px 0px 0px 0px;
    font-family: sans-serif;
  }
  p, pre {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 0px;
    padding: 0px 25px 0px 25px;
    color: ${props => props.theme.text};
    opacity: .87;
    text-indent: 0em;
    text-align: left;
    font-family: sans-serif;
    word-spacing: 6px;
    line-height:125%;
    letter-spacing: 1.2px;
  }
  img {
    max-width: 100%;
  }
  blockquote {
    background-color: antiquewhite;
  }

  .author {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px 50px 10px 25px;

    &:hover {
      opacity: 0.5;
      &:after {
        opacity: 1;
      }
    }

    span {
      color: ${props => props.theme.text};
      font-weight: bold;
      margin-left: 25px;
      font-size: 18px;
      opacity: .87;
      font-family: sans-serif;
      display: flex;
      align-self: center;
      justify-self: flex-start;
    }
    img {
      border-radius: 50%;
      display: flex;
      justify-self: flex-end;
      margin: 0;
    }

    a {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }

  .summaryLevel {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    border-radius: 10px;
    margin:0;
        
    li:nth-child(n+2) {
      padding-top: 8px;
    }

    li  {
      width: 70px;
      height: 90px;
      margin: 0 10px 0 10px;
      justify-content: center;
      display: flex;
    }

    input[type="checkbox"] {
      display: none;
    }

    img {
      max-width: 50px;
      max-height: 50px;
    }
    .icon3 {
      max-width: 70px;
      max-height: 70px;
      margin: 0;
    }
    
  }

  .body{
    max-width: 800px;
    margin: 0 auto;
  }
`

const Suggestion = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin-top: 30px;

  @media (max-width: 800px) {
    flex-direction: column;      
  }

`;

const SuggestionLink = styled.div`
  background: ${props => props.theme.background};  
  color: ${props => props.theme.text};

  border-radius: 10px;
  border: 2px solid ${props => props.theme.text};
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;

  justify-content: center;
  align-items: center;

  cursor: pointer;

  padding: 5px;

  max-width: 300px;

  img {
    padding-top: 25px;
  }

  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
  
`;

export default BlogPost;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulArticle(slug: {eq: $slug}) {
      title
      slug
      body {
        body
      }
      summaryLevel1 {
        childMarkdownRemark {
          html
        }
      }
      summaryLevel2 {
        childMarkdownRemark {
          html
        }
      }
      author {
        slug
        title
        name
        thumbnail {
          resize(height: 60, width: 60) {
            src
          }
          description
        }
      }
    }

    allContentfulArticle(filter: {slug: {ne: $slug}}, limit: 2) {
    edges {
      next {
        id
        slug
        title
        thumbnailText
        thumbnail {
          resize {
            src
          }
          description
        }
      }
      previous {
        id
        slug
        title
        thumbnailText
        thumbnail {
          resize {
            src
          }
          description
        }
      }
    }
  }

  }

`